import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import {
  BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer,
} from 'recharts';
import _ from 'lodash';
import moment from 'moment';
import "../../../styles/styles.scss";

const MonthlyCosts = () => (
  <StaticQuery
    query={graphql`
      query SeamlessMonthlyOrdersQuery {
        allSeamlessJson {
          nodes {
            when_for
            id
          }
        }  
      }
    `}
    render={data => {

      const orders = data.allSeamlessJson.nodes;

      const ordersByMonth = _.countBy(orders, (order) => 
        moment(order.when_for).startOf('month').format('MMM Y')
      );

      const chartData = _.reverse(Object.entries(ordersByMonth).map(([month, count]) => {
        return {
          month,
          number_of_orders: count,
        }
      }));

      return (
        <div style={{ margin: '-20px', height: '500px' }}>
          <ResponsiveContainer width="100%" height={500}>
            <BarChart
              height={500}
              data={chartData}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="month"/>
              <YAxis width={30} />
              <Tooltip />
              <Legend />
              <Bar dataKey="number_of_orders" fill="#6554C0"/>
            </BarChart>
          </ResponsiveContainer>
        </div>
      );
    }}
  />
)

export default MonthlyCosts
